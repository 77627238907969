'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ConfigurableProductOptionFragment,
  ConfigurableProductVariantFragment,
  ProductStockStatus,
} from '@/api'
import { useCartContext } from '@/providers'
import { ConfigurableVariant } from './configurable-variant'
import { ProductDetailVariantsAddToCartButton } from './variants-add-to-cart-button'
import { VariantsCounter } from './variants-counter'
import { useAddToCartBtnText } from '@/common/hooks/use-add-to-cart-btn-text'
import { selectors } from '@/common/constants/selectors-constants'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'
import { delayBeforeClosingProductModal } from '@/common/constants/products'
import {
  ConfigurableVariantAttribute,
  getAvailableAttributeCombinations,
  getPreselectedVariantAttributes,
} from './utils/get-configurable-variant-attributes'
import { AddToCartEventHandlerParams } from '@/providers/cart/utils/add-to-cart.utils'

export type ProductConfigurableVariants = {
  name?: string | null
  sku?: string | null
  manufacturer_info?: {
    name?: string | null
  } | null
  breadcrumb_en?: string | null
  configurable_variants?:
    | (ConfigurableProductVariantFragment | undefined | null)[]
    | null
  configurable_options?:
    | (ConfigurableProductOptionFragment | undefined | null)[]
    | null
}

export type ConfigurableVariantsProps = {
  configurableProductData: ProductConfigurableVariants
  preselectedVariantSku?: string
  isPopupVariant?: boolean
  onClose?: () => void
  onAddToCartEvent?: (params: AddToCartEventHandlerParams) => Promise<void>
}

export function ConfigurableVariants({
  configurableProductData,
  isPopupVariant = false,
  onClose,
  preselectedVariantSku,
  onAddToCartEvent,
}: ConfigurableVariantsProps): JSX.Element {
  const {
    configurable_variants: configurableVariants,
    configurable_options: configurableOptions,
  } = configurableProductData

  const configurableVariantsInStock = configurableVariants?.filter(
    (variant) => variant?.product?.stock_status === ProductStockStatus.InStock,
  )

  const availableAttributeCombinations = useMemo(
    () =>
      getAvailableAttributeCombinations(
        configurableVariantsInStock ?? undefined,
      ),
    [configurableVariantsInStock],
  )
  const selectedVariantAttributes = configurableVariants?.find(
    (variant) => variant?.product?.sku === preselectedVariantSku,
  )?.attributes
  const preselectedVariant: ConfigurableVariantAttribute | undefined =
    getPreselectedVariantAttributes(selectedVariantAttributes ?? undefined)

  const [changeText, setChangeText] = useState(false)
  const [selectedAttributes, setSelectedAttributes] = useState<
    ConfigurableVariantAttribute | undefined
  >(preselectedVariant || availableAttributeCombinations?.at(0))

  const {
    configurableProductVariant,
    setConfigurableProductVariant,
    productVariantCount,
    setProductVariantCount,
  } = useProductDataContext()
  const { isAddingToCart, isCartRefetching, addToCart, isCartFetchError } =
    useCartContext()
  const disabled = isCartRefetching || !addToCart

  const handleAddToCart = useCallback(async () => {
    if (onAddToCartEvent && addToCart) {
      setChangeText(true)
      await onAddToCartEvent?.({
        count: productVariantCount,
        variant: configurableProductVariant,
        product: configurableProductData,
        addToCart,
      })
    }

    if (onClose) {
      setTimeout(() => onClose(), delayBeforeClosingProductModal)
    }
  }, [
    onClose,
    onAddToCartEvent,
    addToCart,
    productVariantCount,
    configurableProductVariant,
    configurableProductData,
  ])

  useEffect(() => {
    if (!disabled) {
      setChangeText(false)
    }
  }, [disabled])

  useEffect(() => {
    const selectedVariant = configurableVariants?.find((variant) => {
      const variantAttributes = variant?.attributes

      return Object.keys(selectedAttributes ?? {}).every((key) => {
        const selectedValueIndex = selectedAttributes?.[key]?.valueIndex

        return variantAttributes?.some(
          (attr) =>
            attr?.code === key && attr?.value_index === selectedValueIndex,
        )
      })
    })

    if (selectedVariant) {
      setConfigurableProductVariant(selectedVariant)
    }
  }, [selectedAttributes, setConfigurableProductVariant, configurableVariants])

  const buttonText = useAddToCartBtnText({
    changeText,
    isAddingToCart,
    isCartRefetching,
    isCartFetchError,
  })

  const sortedConfigurableOptions = configurableOptions
    ?.slice(0)
    .sort((a, b) => ((a?.position ?? 0) < (b?.position ?? 0) ? -1 : 1))
  const isMultiVariant =
    configurableProductData.configurable_variants &&
    configurableProductData.configurable_variants?.length > 1

  return (
    <>
      <div className="mb-4 flex flex-wrap">
        <div
          className={`
            w-full flex flex-col flex-wrap data-[ispopup=false]:md:flex-row
            md:space-x-2 space-y-2 md:space-y-0
            data-[ispopup=true]:space-x-0
            data-[ispopup=true]:space-y-2
          `}
          data-test={selectors.HP.productVariantSection}
          data-ispopup={isPopupVariant}
        >
          {isMultiVariant &&
            sortedConfigurableOptions?.map((option) => (
              <ConfigurableVariant
                disabled={disabled}
                label={option?.label ?? ''}
                key={option?.attribute_code}
                option={option ?? undefined}
                configurableOptions={sortedConfigurableOptions}
                onChange={(updatedSelectedAttributes) => {
                  setSelectedAttributes(updatedSelectedAttributes)
                }}
                selectedAttributes={selectedAttributes}
                availableAttributeCombinations={availableAttributeCombinations}
              />
            ))}
          <VariantsCounter
            count={productVariantCount}
            onCountChange={setProductVariantCount}
            onAddToCart={handleAddToCart}
            isDisabled={disabled}
            className={`w-[120px] ${
              !isPopupVariant && 'md:w-[105px] md:self-end'
            }`}
          />
        </div>
      </div>
      <ProductDetailVariantsAddToCartButton
        className="md:w-full lg:w-full"
        addToCart={handleAddToCart}
        buttonText={buttonText}
      />
    </>
  )
}
